import React from 'react';

import { useTranslation } from 'react-i18next';

import _get from 'lodash/get';

import Button from 'react-bootstrap/Button';

import '../Flow.scss';

type Props = {
  artwork: Object,
  userProfile: Object,
  onMarkAsRead: Function,
};

/**
 * FLOW TEASER
 */
const Content = ({ artwork, onMarkAsRead }: Props) => {
  const { t } = useTranslation('folder');

  const hasUnread = _get(artwork, 'artworkAccess.unreadCount') > 0;
  return (
    <>
      <div className="Flow-mainContent">
        <div className="Flow-separatorZone">
          <div className="Flow-separator"> </div>
        </div>
        <div className="text-neutral flowfont-regular Flow-respectLine Flow-description">
          {artwork.flowDescription || t('global:default-description')}
        </div>
      </div>
      {hasUnread && (
        <Button variant="link" className="ml-auto d-block flowfont-medium" onClick={onMarkAsRead}>
          {t('mark-all-as-read')}
        </Button>
      )}
    </>
  );
};

export default React.memo(Content);
